<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="#6c757d"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
                  <v-img
                class="text-center"
                src="/media/gooselogo.png"
                max-width="250px"
                
                >   </v-img>

             

           
            </div>
          </template>

          <v-card-text class="text-center">
            <div class="text-center grey--text body-1 font-weight-light">
              Login Now
            </div>


         



       <v-form class="form" @submit.stop.prevent="onSubmit">
        <div
          role="alert"
          class="alert alert-success login-success"
          v-if="reg_success"
        >
          <div class="alert-text">
            Successfully registered!
          </div>
        </div>


        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error.message }}
          </div>
        </div>

      
          <v-text-field
          dense
          
            class=" h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.identifier.$model"
            :state="validateState('identifier')"
            aria-describedby="input-1-live-feedback"
            placeholder="Email"
          > </v-text-field>

       
       

        
          <v-text-field
            class=" h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
            placeholder="Password"
          > </v-text-field>

        
     

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            class="text-dark-60 text-hover-primary my-3 mr-2 cursor-pointer"
            id="kt_login_forgot"
            @click="$router.push('/pages/forgot')"
          >
            Forgot Password ?
          </a>


   
          <button

            ref="kt_login_signin_submit"
            class="btn btn-secondary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Sign In
          </button>
        </div>
        <!--end::Action-->
      </v-form>





          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {


 

 
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      
        loader: null,
        loading: false,

      reg_success: false,
      // Remove this dummy login info
      form: {
        identifier: null,
        password: null
      }
    };
  },
  validations: {
    form: {
      identifier: {
        required
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },

   watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
    },


  methods: {
    ...mapActions([
      'getUserInfo'
    ]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        identifier: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const identifier = this.$v.form.identifier.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { identifier, password })
          // go to which page after successfully login
          .then(() => {
            this.$router.push({ path: "/dashboard" });
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    if(this.$route.params.register == true)
    {
      this.form.identifier = '';
      this.form.password = '';
      this.reg_success = true;
      // this.$bvToast.toast('Successfully Registered!', {
      //   title: 'Success',
      //   variant: 'success',
      //   toaster: 'b-toaster-bottom-right',
      //   solid: true,
      //   appendToast: true
      // });
    }
  }
};
</script>

<style scoped>
  @media (min-width: 730px) {
    .login-success {
      min-width: 400px;
    }
  }
</style>